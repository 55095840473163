import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import {useContext} from 'react';
import {AppContext} from '../../AppContext/AppContext';
import significantDigits from '../../util/significantDigits';

export default function ScaleRanges() {
  const {effectsTableRows} = useContext(AppContext);

  function createScaleRangesRows() {
    return _.map(effectsTableRows, (criterion) => {
      return (
        <TableRow key={criterion.mcdaId}>
          <TableCell id={`scale-ranges-title-${criterion.mcdaId}`}>
            {criterion.title}
          </TableCell>
          <TableCell
            id={`scale-ranges-configured-${criterion.mcdaId}`}
          >{`${significantDigits(
            Math.min(...criterion.scales)
          )}, ${significantDigits(Math.max(...criterion.scales))}`}</TableCell>
          <TableCell id={`scale-ranges-unit-${criterion.mcdaId}`}>
            {criterion.unitOfMeasurement}
          </TableCell>
        </TableRow>
      );
    });
  }

  return (
    <span>
      <Typography variant="h6">Scale Ranges</Typography>
      <Table id="scale-ranges" size="small">
        <TableHead>
          <TableRow>
            <TableCell>Criterion</TableCell>
            <TableCell>Configured Range</TableCell>
            <TableCell>Units</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{createScaleRangesRows()}</TableBody>
      </Table>
    </span>
  );
}
