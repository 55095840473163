import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import {useContext} from 'react';
import {AppContext} from '../../AppContext/AppContext';
import IRow from '../../Interface/IRow';
import significantDigits from '../../util/significantDigits';

export default function EffectsTable() {
  const {alternatives, effectsTableRows} = useContext(AppContext);

  function createValueColumns(criterion: IRow) {
    return _.map(alternatives, (alternative) => (
      <TableCell id={`criterion-title-${criterion.mcdaId}-${alternative.id}`}>
        <div className="centered">
          {significantDigits(criterion.values[alternative.id].median)}
        </div>
        <div className="centered">
          <div className="centered uncertain">
            {significantDigits(criterion.values[alternative.id].lowerBound)},{' '}
            {significantDigits(criterion.values[alternative.id].upperBound)}
          </div>
        </div>
      </TableCell>
    ));
  }

  function createAlternativeHeaders() {
    return _.map(alternatives, (alternative) => {
      return (
        <TableCell id={`alternative-title-${alternative.id}`} align="center">
          {alternative.title}
        </TableCell>
      );
    });
  }

  return (
    <span>
      <Typography variant="h6">Effects table</Typography>
      <Table id="effects-table" size="small">
        <TableHead>
          <TableRow>
            <TableCell>Criterion</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Unit</TableCell>
            {createAlternativeHeaders()}
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(effectsTableRows, (criterion: IRow) => {
            return (
              <TableRow key={criterion.mcdaId}>
                <TableCell id={`criterion-title-${criterion.mcdaId}`}>
                  {criterion.title}
                </TableCell>
                <TableCell id={`criterion-description-${criterion.mcdaId}`}>
                  {criterion.description}
                </TableCell>
                <TableCell id={`criterion-unit-${criterion.mcdaId}`}>
                  {criterion.unitOfMeasurement}
                </TableCell>
                {createValueColumns(criterion)}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </span>
  );
}
