import {TableCell, TableRow} from '@material-ui/core';
import _ from 'lodash';
import {useContext} from 'react';
import {AppContext} from '../../../../AppContext/AppContext';
import IExtraQuestion from '../../../../Interface/IExtraQuestion';
import IResponse from '../../../../Interface/IResponse';
import IRow from '../../../../Interface/IRow';
import {calculateImportance} from '../../ResponseUtil/ResponseUtil';

export default function ResponseRow({
  response,
  showExtraQuestions
}: {
  response: IResponse;
  showExtraQuestions: boolean;
}) {
  const {effectsTableRows, elicitationMethod, extraQuestions} =
    useContext(AppContext);

  function createImportanceCells(): JSX.Element[] {
    return _.map(effectsTableRows, (criterion: IRow) => {
      return (
        <TableCell
          id={`importance-${response.name}-${criterion.mcdaId}`}
          key={criterion.mcdaId}
        >
          {calculateImportance(
            criterion.mcdaId,
            response.results,
            elicitationMethod
          )}
        </TableCell>
      );
    });
  }

  function createExtraAnswerCells(): JSX.Element[] {
    return _.map(
      extraQuestions,
      (extraquestion: IExtraQuestion): JSX.Element => (
        <TableCell>{response.extraAnswers![extraquestion.id]}</TableCell>
      )
    );
  }

  return (
    <TableRow key={response.name}>
      <TableCell id={`response-from-${response.name}`}>
        {response.name}
      </TableCell>
      {createImportanceCells()}
      {showExtraQuestions ? createExtraAnswerCells() : <></>}
    </TableRow>
  );
}
