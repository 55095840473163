import Grid from '@material-ui/core/Grid';
import {useContext} from 'react';
import {AppContext} from '../../AppContext/AppContext';
import PublishSurveyButton from '../PublishSurveyButton/PublishSurveyButton';
import SurveyLink from '../SurveyLink/SurveyLink';
import CloseSurveyButton from './CloseSurveyButton/CloseSurveyButton';

export default function PublishSurvey() {
  const {status} = useContext(AppContext);
  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item container xs={12} spacing={2}>
        <Grid item>
          <PublishSurveyButton />
        </Grid>
        <Grid item>
          <CloseSurveyButton />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {status === 'published' ? <SurveyLink /> : <span></span>}
      </Grid>
    </Grid>
  );
}
