import {IconButton} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Edit from '@material-ui/icons/Edit';
import {ChangeEvent, useEffect, useState} from 'react';
import DialogTitleWithCross from '../../DialogTitleWithCross/DialogTitleWithCross';

export default function EditFieldModal({
  buttonId,
  initialValue,
  propertyName,
  propertyField,
  saveCallback,
  fieldType,
  visible,
  cantBeEmpty
}: {
  buttonId: string;
  initialValue: string | undefined;
  propertyName: string;
  propertyField: string;
  saveCallback: (newValue: string) => void;
  fieldType: string;
  visible: boolean;
  cantBeEmpty?: boolean;
}) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [value, setValue] = useState(initialValue ? initialValue : '');

  useEffect(
    () => setValue(initialValue ? initialValue : ''),
    [isDialogOpen, initialValue]
  );

  function toggleDialog() {
    setIsDialogOpen(!isDialogOpen);
  }

  function handleClick() {
    saveCallback(value);
    toggleDialog();
  }

  function handleChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    setValue(event.target.value);
  }

  return visible ? (
    <span>
      <IconButton
        size="small"
        color="primary"
        onClick={toggleDialog}
        id={buttonId}
      >
        <Edit />
      </IconButton>
      <Dialog
        open={isDialogOpen}
        onClose={toggleDialog}
        fullWidth
        maxWidth={'sm'}
      >
        <DialogTitleWithCross id="edit-field-dialog" onClose={toggleDialog}>
          Edit {propertyName}
        </DialogTitleWithCross>
        <DialogContent>
          <TextField
            id="property-input"
            label={propertyField}
            variant="outlined"
            value={value}
            onChange={handleChange}
            rows="10"
            multiline={fieldType !== 'input'}
            fullWidth
            helperText={!value && cantBeEmpty ? 'No value entered' : ''}
            error={!value && cantBeEmpty}
          />
        </DialogContent>
        <DialogActions>
          <Grid container xs={12} justify="flex-start" spacing={2}>
            <Grid item>
              <Button
                id="modal-save-button"
                color="primary"
                variant="contained"
                onClick={handleClick}
                disabled={cantBeEmpty && !value}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </span>
  ) : (
    <span></span>
  );
}
