import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import Axios from 'axios';
import {useContext, useState} from 'react';
import {AppContext} from '../../../../../../AppContext/AppContext';
import DialogTitleWithCross from '../../../../../../DialogTitleWithCross/DialogTitleWithCross';
import IExtraQuestion from '../../../../../../Interface/IExtraQuestion';
import AsyncSingleClickButton from '../../../../../../util/AsyncSingleClickButton/AsyncSingleClickButton';

export default function DeleteQuestionButton({
  extraQuestion,
  index
}: {
  extraQuestion: IExtraQuestion;
  index: number;
}): JSX.Element {
  const {id, deleteQuestion} = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);

  function closeDialog() {
    setIsOpen(false);
  }

  function openDialog() {
    setIsOpen(true);
  }

  const handleDeleteIconClick = openDialog;

  function handleConfirmDeleteClick() {
    return Axios.delete(
      `/api/v1/survey/${id}/extraQuestion/${extraQuestion.id}`
    ).then(() => {
      deleteQuestion(extraQuestion.id);
      closeDialog();
    });
  }

  return (
    <>
      <Tooltip title="Delete question">
        <IconButton
          id={`delete-question-${index}`}
          size="small"
          color="secondary"
          onClick={handleDeleteIconClick}
        >
          <Delete />
        </IconButton>
      </Tooltip>
      <Dialog open={isOpen} onClose={closeDialog}>
        <DialogTitleWithCross
          id="delete-question-dialog-title"
          onClose={closeDialog}
        >
          Delete question
        </DialogTitleWithCross>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography>
                This will delete the question: <em>{extraQuestion.question}</em>
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container xs={12} justify="flex-start" spacing={2}>
            <Grid item>
              <AsyncSingleClickButton
                id="confirm-question-delete"
                asyncFunction={handleConfirmDeleteClick}
                variant="contained"
                color="secondary"
              >
                Delete
              </AsyncSingleClickButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}
