import {Checkbox, Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Refresh from '@material-ui/icons/Refresh';
import Axios, {AxiosError} from 'axios';
import {ChangeEvent, useCallback, useContext, useEffect, useState} from 'react';
import {AppContext} from '../../AppContext/AppContext';
import {ErrorContext} from '../../Error/ErrorContext';
import AsyncSingleClickButton from '../../util/AsyncSingleClickButton/AsyncSingleClickButton';
import ResponseTable from './ResponseTable/ResponseTable';
export default function Responses() {
  const {setError} = useContext(ErrorContext);
  const {id, areExtraQuestionsOn} = useContext(AppContext);
  const [responses, setResponses] = useState([]);
  const [showExtraQuestions, setShowExtraQuestions] = useState(false);

  const getResponses = useCallback(() => {
    return Axios.get(`/api/v1/survey/${id}/responses`)
      .then((axiosResponse) => {
        setResponses(axiosResponse.data);
      })
      .catch((error: AxiosError) => {
        setError(error.message + ', ' + error.response!.data);
      });
  }, [id, setError]);

  useEffect(() => {
    getResponses();
  }, [getResponses]);

  function handleCheckboxChanged(
    _event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    setShowExtraQuestions(checked);
  }

  return (
    <Grid item container spacing={2} xs={12}>
      <Grid item xs={12}>
        <AsyncSingleClickButton
          id="get-responses-button"
          asyncFunction={getResponses}
          color="primary"
          variant="contained"
          startIcon={<Refresh />}
        >
          Refresh
        </AsyncSingleClickButton>
      </Grid>
      {areExtraQuestionsOn ? (
        <Grid item xs={12}>
          <Typography display="inline">
            Show answers to additional questions:
          </Typography>
          <Checkbox
            id="optional-questions-toggle"
            checked={showExtraQuestions}
            onChange={handleCheckboxChanged}
            color="primary"
          />
        </Grid>
      ) : (
        <></>
      )}
      <Grid item xs={12}>
        <ResponseTable
          responses={responses}
          showExtraQuestions={showExtraQuestions}
        />
      </Grid>
    </Grid>
  );
}
