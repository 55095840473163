import {useContext} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {AppContext} from '../AppContext/AppContext';
import SurveyOverview from '../SurveyOverview/SurveyOverview';
import SurveyIsClosedScreen from '../TakeSurvey/SurveyIsClosedScreen';
import TakeSurvey from '../TakeSurvey/TakeSurvey';
import TestRun from '../TestRun/TestRun';

export default function AppRouter({path}: {path: string}) {
  const {id, isUserOwner} = useContext(AppContext);

  return (
    <Switch>
      <Route path={`${path}/overview`} component={SurveyOverview} />
      <Route path={`${path}/testRun`} component={TestRun} />
      <Route
        path={`${path}/takeSurvey/closed`}
        component={SurveyIsClosedScreen}
      />
      <Route path={`${path}/takeSurvey`} component={TakeSurvey} />
      <Route path="/survey/:surveyId">
        {isUserOwner ? (
          <Redirect to={`/survey/${id}/overview`} />
        ) : (
          <Redirect to={`/survey/${id}/takeSurvey`} />
        )}
      </Route>
    </Switch>
  );
}
