import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import axios, {AxiosError} from 'axios';
import {useContext} from 'react';
import {AppContext} from '../../../AppContext/AppContext';
import {ErrorContext} from '../../../Error/ErrorContext';
import TemplateButtons from '../TemplateButtons/TemplateButtons';

export default function Introduction() {
  const {id, introduction, setIntroduction} = useContext(AppContext);
  const {setError} = useContext(ErrorContext);

  function saveIntroduction(newIntroduction: string) {
    setIntroduction(newIntroduction);
    updateIntroduction(newIntroduction);
  }

  function updateIntroduction(newIntroduction: string) {
    axios
      .put(`/api/v1/survey/${id}/introduction`, {
        newIntroduction: newIntroduction
      })
      .catch((error: AxiosError) => {
        setError(error.message + ', ' + error.response!.data);
      });
  }

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Survey introduction</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        id="survey-introduction"
        dangerouslySetInnerHTML={{__html: introduction}}
        className="margin-bottom template"
      />
      <Grid item xs={12}>
        <TemplateButtons
          id="introduction-template-edit-button"
          templateType={'introduction'}
          saveCallback={saveIntroduction}
        />
      </Grid>
    </Grid>
  );
}
