import _ from 'lodash';
import {PreferenceElicitation} from 'preference-elicitation';
import {PreferenceElicitationPrivate} from 'preference-elicitation-private';
import {useContext} from 'react';
import {AppContext} from '../AppContext/AppContext';
import {
  buildPvfs,
  getThresholdValues,
  isPrivateElicitationView,
  isPublicElicitationView,
  mapElicitationCriteria,
  mapStepSizes
} from '../Elicitation/ElicitationUtil';
import {ErrorContext} from '../Error/ErrorContext';
import {TPreferences} from '../Interface/PreferenceElicitation/TPreferences';
import IntroductionInformation from '../IntroductionInformation/IntroductionInformation';
import {SurveyContext} from '../SurveyContext/SurveyContext';
import ThankYouScreen from '../TakeSurvey/ThankYouScreen';
import AskExtraQuestions from './AskExtraQuestions/AskExtraQuestions';

export default function Survey({
  cancelCallback
}: {
  cancelCallback?: () => void;
}) {
  const {
    criteria,
    currentStep,
    isSurveyDone,
    extraAnswers,
    finishCallback,
    setIsSurveyDone,
    setCurrentStep,
    name
  } = useContext(SurveyContext);
  const {
    elicitationMethod,
    elicitationTemplate,
    showCbmPieChart,
    areExtraQuestionsOn
  } = useContext(AppContext);
  const {setError} = useContext(ErrorContext);

  const preferenceCriteria = mapElicitationCriteria(criteria);
  const pvfs = buildPvfs(criteria);
  const stepSizesByCriterion: Record<string, number> = mapStepSizes(criteria);
  const showPercentages = _.some(criteria, [
    'unitOfMeasurementType',
    'percentage'
  ]);

  function renderView() {
    if (isSurveyDone) {
      return <ThankYouScreen />;
    } else {
      if (currentStep === 1) {
        return <IntroductionInformation />;
      } else if (currentStep === 2 && areExtraQuestionsOn) {
        return <AskExtraQuestions />;
      } else {
        return renderElicitation();
      }
    }
  }

  function saveCallback(
    preferences: TPreferences,
    thresholdValuesByCriterion?: Record<string, number>
  ): Promise<any> {
    return finishCallback({
      name: name,
      preferences: preferences,
      thresholdValuesByCriterion: getThresholdValues(
        criteria,
        thresholdValuesByCriterion
      ),
      extraAnswers
    }).then(() => {
      setIsSurveyDone(true);
    });
  }

  function previousCallback(): void {
    if (areExtraQuestionsOn) {
      setCurrentStep(2);
    } else {
      setCurrentStep(1);
    }
  }

  function renderElicitation(): JSX.Element {
    if (isPublicElicitationView(elicitationMethod)) {
      return (
        <PreferenceElicitation
          cancelCallback={cancelCallback}
          criteria={preferenceCriteria}
          elicitationMethod={elicitationMethod}
          manualHost=""
          manualLexicon={{}}
          manualPath=""
          previousCallback={previousCallback}
          pvfs={pvfs}
          saveCallback={saveCallback}
          showPercentages={showPercentages}
          stepSizesByCriterion={stepSizesByCriterion}
          template={elicitationTemplate}
        />
      );
    } else if (isPrivateElicitationView(elicitationMethod)) {
      return (
        <PreferenceElicitationPrivate
          cancelCallback={cancelCallback}
          criteria={preferenceCriteria}
          elicitationMethod={elicitationMethod}
          manualHost=""
          manualLexicon={{}}
          manualPath=""
          previousCallback={previousCallback}
          pvfs={pvfs}
          saveCallback={saveCallback}
          setErrorMessage={setError}
          showCbmPieChart={showCbmPieChart}
          showPercentages={showPercentages}
          stepSizeByCriterion={stepSizesByCriterion}
        />
      );
    } else {
      setError('Invalid elicitation method');
      return <span />;
    }
  }

  return renderView();
}
