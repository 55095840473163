import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import axios, {AxiosError} from 'axios';
import {useContext} from 'react';
import {AppContext} from '../../../AppContext/AppContext';
import {ErrorContext} from '../../../Error/ErrorContext';
import TemplateButtons from '../TemplateButtons/TemplateButtons';

export default function FinishSurveyMessage() {
  const {id, finishSurveyMessage, setFinishSurveyMessage} =
    useContext(AppContext);
  const {setError} = useContext(ErrorContext);

  function saveFinishSurveyMessage(message: string) {
    setFinishSurveyMessage(message);
    updateMessage(message);
  }

  function updateMessage(message: string) {
    axios
      .put(`/api/v1/survey/${id}/finishSurveyMessage`, {
        finishSurveyMessage: message
      })
      .catch((error: AxiosError) => {
        setError(error.message + ', ' + error.response!.data);
      });
  }

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Message after finishing the survey</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        id="finish-survey-message"
        dangerouslySetInnerHTML={{__html: finishSurveyMessage}}
        className="margin-bottom template"
      />
      <Grid item xs={12}>
        <TemplateButtons
          id="finish-survey-message-template-edit-button"
          templateType={'finishSurveyMessage'}
          saveCallback={saveFinishSurveyMessage}
        />
      </Grid>
    </Grid>
  );
}
