import Typography from '@material-ui/core/Typography';
import axios, {AxiosError} from 'axios';
import {useContext} from 'react';
import {AppContext} from '../../AppContext/AppContext';
import {ErrorContext} from '../../Error/ErrorContext';
import EditFieldModal from '../EditFieldModal/EditFieldModal';

export default function EditTitle() {
  const {id, title, setTitle, status} = useContext(AppContext);
  const {setError} = useContext(ErrorContext);

  function handleTitleChange(newTitle: string) {
    setTitle(newTitle);
    updateTitle(newTitle);
    document.title = `Survey: ${newTitle}`;
  }

  function updateTitle(newTitle: string) {
    axios
      .put(`/api/v1/survey/${id}/title`, {
        newTitle: newTitle
      })
      .catch((error: AxiosError) => {
        setError(error.message + ', ' + error.response!.data);
      });
  }

  return (
    <Typography id="survey-title" variant="h5" align="center">
      {title}{' '}
      <EditFieldModal
        buttonId={'edit-survey-title'}
        initialValue={title}
        saveCallback={handleTitleChange}
        propertyName="survey"
        propertyField="Title"
        fieldType="input"
        visible={status === 'created'}
        cantBeEmpty={true}
      />
    </Typography>
  );
}
