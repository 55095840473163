import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Axios, {AxiosError} from 'axios';
import {useContext, useState} from 'react';
import {AppContext} from '../../../AppContext/AppContext';
import DialogTitleWithCross from '../../../DialogTitleWithCross/DialogTitleWithCross';
import {ErrorContext} from '../../../Error/ErrorContext';
import InlineEditor from '../../../InlineEditor/InlineEditor';
import IUpdateTemplateCommand from '../../../Interface/Commands/IUpdateTemplateCommand';
import {TemplateType} from '../../../Type/TemplateType';

export default function EditTemplatesDialog({
  isDialogOpen,
  closeDialog
}: {
  isDialogOpen: boolean;
  closeDialog: () => void;
}): JSX.Element {
  const {
    elicitationMethod,
    elicitationTemplate,
    finishSurveyMessage,
    id,
    identificationQuestion,
    introduction,
    setElicitationTemplate,
    setFinishSurveyMessage,
    setIdentificationQuestion,
    setIntroduction,
    getDefaultTemplateByType
  } = useContext(AppContext);
  const {setError} = useContext(ErrorContext);

  const [newIntroduction, setNewIntroduction] = useState(introduction);
  const [newIdentificationQuestion, setNewIdentificationQuestion] = useState(
    identificationQuestion
  );
  const [newElicitationTemplate, setNewElicitationTemplate] =
    useState(elicitationTemplate);
  const [newFinishSurveyMessage, setNewFinishSurveyMessage] =
    useState(finishSurveyMessage);

  function resetIntroduction(): void {
    setNewIntroduction(getDefaultTemplateByType('introduction'));
  }

  function resetIdentificationQuestion(): void {
    setNewIdentificationQuestion(
      getDefaultTemplateByType('identificationQuestion')
    );
  }

  function resetElicitationTemplate(): void {
    setNewElicitationTemplate(
      getDefaultTemplateByType(elicitationMethod as TemplateType)
    );
  }

  function resetFinishSurveyMessage(): void {
    setNewFinishSurveyMessage(getDefaultTemplateByType('finishSurveyMessage'));
  }

  function handleSave(): void {
    const command: IUpdateTemplateCommand = {
      introduction: newIntroduction,
      identificationQuestion: newIdentificationQuestion,
      elicitation: newElicitationTemplate,
      finishMessage: newFinishSurveyMessage
    };
    Axios.put(`/api/v1/survey/${id}/updateTemplates`, command)
      .then(() => {
        setIntroduction(newIntroduction);
        setIdentificationQuestion(newIdentificationQuestion);
        setElicitationTemplate(newElicitationTemplate);
        setFinishSurveyMessage(newFinishSurveyMessage);
      })
      .catch((error: AxiosError) => {
        setError(error.message + ', ' + error.response!.data);
      });
    closeDialog();
  }

  function getTitle(): string | undefined {
    if (elicitationMethod === 'precise') {
      return 'Question during precise swing elicitation';
    } else if (elicitationMethod === 'matching') {
      return 'Question during matching elicitation';
    } else {
      setError('Templates not supported for: ' + elicitationMethod);
    }
  }

  return (
    <Dialog open={isDialogOpen} onClose={closeDialog} fullWidth maxWidth={'lg'}>
      <DialogTitleWithCross id="edit-template-dialog" onClose={closeDialog}>
        Edit templates
      </DialogTitleWithCross>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">Survey introduction</Typography>
          </Grid>
          <Grid item xs={12} id="edit-introduction-container">
            <InlineEditor
              template={newIntroduction}
              defaultTemplate={getDefaultTemplateByType('introduction')}
              callback={setNewIntroduction}
              resetCallback={resetIntroduction}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">
              Question asked to identify the surveyee:
            </Typography>
          </Grid>
          <Grid item xs={12} id="edit-question-container">
            <InlineEditor
              template={newIdentificationQuestion}
              defaultTemplate={getDefaultTemplateByType(
                'identificationQuestion'
              )}
              callback={setNewIdentificationQuestion}
              resetCallback={resetIdentificationQuestion}
            />
          </Grid>
          {elicitationMethod === 'matching' ||
          elicitationMethod === 'precise' ? (
            <>
              <Grid item xs={12}>
                <Typography variant="h6">{getTitle()}</Typography>
              </Grid>
              <Grid item xs={12} id="edit-elicitation-template-container">
                <InlineEditor
                  template={newElicitationTemplate}
                  defaultTemplate={getDefaultTemplateByType(
                    elicitationMethod as TemplateType
                  )}
                  callback={setNewElicitationTemplate}
                  resetCallback={resetElicitationTemplate}
                />
              </Grid>
            </>
          ) : (
            <></>
          )}
          <Grid item xs={12}>
            <Typography variant="h6">
              Message after finishing the survey
            </Typography>
          </Grid>
          <Grid item xs={12} id="edit-finish-survey-message-container">
            <InlineEditor
              template={newFinishSurveyMessage}
              defaultTemplate={getDefaultTemplateByType('finishSurveyMessage')}
              callback={setNewFinishSurveyMessage}
              resetCallback={resetFinishSurveyMessage}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container xs={12} justify="flex-start" spacing={2}>
          <Grid item>
            <Button
              id="save-templates-button"
              color="primary"
              variant="contained"
              onClick={handleSave}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
