import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {useContext} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {AppContext} from '../AppContext/AppContext';
import {ErrorContext} from '../Error/ErrorContext';
import Criteria from './Criteria/Criteria';
import CustomizeAndTest from './CustomizeAndTest/CustomizeTexts';
import EditTitle from './EditTitle/EditTitle';
import ProblemDefition from './ProblemDefinition/ProblemDefinition';
import PublishSurvey from './PublishSurvey/PublishSurvey';
import PVFSettings from './PVFSettings/PVFSettings';
import Responses from './Responses/Responses';
import SetElicitationMethod from './SetElicitationMethod/SetElicitationMethod';
import SurveyStatus from './SurveyStatus/SurveyStatus';
import SurveyTabs from './SurveyTabs/SurveyTabs';

export default function SurveyOverview({
  match
}: {
  match: {params: {surveyId: string}; path: string};
}) {
  const {isUserOwner} = useContext(AppContext);
  const {setError} = useContext(ErrorContext);

  if (!isUserOwner) {
    setError('Insufficient rights');
  }

  return (
    <Grid container justify="center" component={Box} mt={2}>
      <Grid container item sm={12} md={8} spacing={2} component={Paper}>
        <Grid item xs={12}>
          <EditTitle />
        </Grid>
        <Grid item xs={12}>
          <SurveyStatus />
        </Grid>
        <Grid item xs={12}>
          <SurveyTabs surveyId={match.params.surveyId} />
        </Grid>
        <Grid item container xs={12}>
          <Switch>
            <Route
              path={`${match.path}/problemDefinition`}
              component={ProblemDefition}
            />
            <Route path={`${match.path}/criteria`} component={Criteria} />
            <Route path={`${match.path}/pvfs`} component={PVFSettings} />
            <Route
              path={`${match.path}/elicitationMethod`}
              component={SetElicitationMethod}
            />
            <Route
              path={`${match.path}/templates`}
              component={CustomizeAndTest}
            />
            <Route path={`${match.path}/publish`} component={PublishSurvey} />
            <Route path={`${match.path}/responses`} component={Responses} />
            <Route path="/survey/:surveyId/overview">
              <Redirect
                to={`/survey/${match.params.surveyId}/overview/problemDefinition`}
              />
            </Route>
          </Switch>
        </Grid>
      </Grid>
    </Grid>
  );
}
