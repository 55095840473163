import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {useContext, useEffect} from 'react';
import {useHistory} from 'react-router';
import {AppContext} from '../AppContext/AppContext';
import {buildCriteria} from '../Elicitation/ElicitationUtil';
import {ErrorContext} from '../Error/ErrorContext';
import Survey from '../Survey/Survey';
import {SurveyContextProviderComponent} from '../SurveyContext/SurveyContext';
import TestRunButtons from './TestRunButtons/TestRunButtons';

export default function TestRun() {
  const {isUserOwner, effectsTableRows} = useContext(AppContext);
  const {setError} = useContext(ErrorContext);
  const {id} = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    if (!isUserOwner) {
      setError('Insufficient rights');
    }
  }, [isUserOwner, setError]);

  function cancelCallback(): void {
    history.push(`/survey/${id}/overview/templates`);
  }

  return (
    <SurveyContextProviderComponent
      inputCriteria={buildCriteria(effectsTableRows)}
      finishCallback={() => Promise.resolve()}
    >
      <Grid container justify="center" component={Box} mt={2}>
        <Survey cancelCallback={cancelCallback} />
        <TestRunButtons />
      </Grid>
    </SurveyContextProviderComponent>
  );
}
