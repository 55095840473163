import Select from '@material-ui/core/Select';
import axios, {AxiosError} from 'axios';
import {ChangeEvent, useContext} from 'react';
import {AppContext} from '../../../AppContext/AppContext';
import {ErrorContext} from '../../../Error/ErrorContext';
import {ElicitationMethod} from '../../../Type/ElicitationMethod';
import {TemplateType} from '../../../Type/TemplateType';

export default function ElicitationChoices() {
  const {
    id,
    elicitationMethod,
    setElicitationMethod,
    status,
    setElicitationTemplate,
    getDefaultTemplateByType
  } = useContext(AppContext);
  const {setError} = useContext(ErrorContext);

  function handleChange(event: ChangeEvent<{value: unknown}>) {
    const elicitationMethod = event.target.value as ElicitationMethod;
    setElicitationMethod(elicitationMethod);
    if (elicitationMethod !== 'ranking') {
      setElicitationTemplate(
        getDefaultTemplateByType(elicitationMethod as TemplateType)
      );
    }
    updateElicitationMethod(id, elicitationMethod);
  }

  function updateElicitationMethod(
    surveyId: string,
    newElicitationMethod: string
  ) {
    axios
      .put(`/api/v1/survey/${surveyId}/elicitationMethod`, {
        newElicitationMethod: newElicitationMethod
      })
      .catch((error: AxiosError) => {
        setError(error.message + ', ' + error.response!.data);
      });
  }

  return (
    <Select
      native
      id="elicitation-method-selector"
      name="elicitation-method-select"
      value={elicitationMethod}
      disabled={status !== 'created'}
      onChange={handleChange}
    >
      <option value="ranking">Ranking</option>
      <option value="matching">Matching</option>
      <option value="precise">Precise swing</option>
      <option value="choice">Choice based matching</option>
      <option value="threshold">Threshold</option>
    </Select>
  );
}
