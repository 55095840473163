import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {useContext} from 'react';
import {AppContext} from '../../AppContext/AppContext';
import ShowIf from '../../util/ShowIf/ShowIf';
import ChoiceBasedMatchingWarningAndOptions from './ChoiceBasedMatchingWarningAndOptions/ChoiceBasedMatchingWarningAndOptions';
import ElicitationChoices from './ElicitationChoices/ElicitationChoices';
import StepSizeTable from './StepSizeTable/StepSizeTable';

export default function SetElicitationMethod() {
  const {elicitationMethod} = useContext(AppContext);

  return (
    <Grid item container xs={12}>
      <Grid item xs={12}>
        <Typography variant="h6">
          Please select an elicitation method:{' '}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ElicitationChoices />
      </Grid>
      <ShowIf condition={elicitationMethod === 'matching'}>
        <Grid item xs={12}>
          <StepSizeTable />
        </Grid>
      </ShowIf>
      <ShowIf condition={elicitationMethod === 'choice'}>
        <Grid item xs={12}>
          <ChoiceBasedMatchingWarningAndOptions />
        </Grid>
      </ShowIf>
    </Grid>
  );
}
