import Grid from '@material-ui/core/Grid';
import {useContext} from 'react';
import {AppContext} from '../../AppContext/AppContext';
import ElicitationTemplate from './ElicitationTemplate/ElicitationTemplate';
import ExtraQuestionsSection from './ExtraQuestionsSection/ExtraQuestionsSection';
import FinishSurveyMessage from './FinishSurveyMessage/FinishSurveyMessage';
import IdentificationQuestionTemplate from './IdentifictionQuestionTemplate/IdentificationQuestionTemplate';
import Introduction from './Introduction/Introduction';
import TestRunButton from './TestRunButton/TestRunButton';

export default function CustomizeAndTest() {
  const {elicitationMethod} = useContext(AppContext);

  return (
    <Grid container item spacing={2} xs={12}>
      <Grid item xs={12}>
        <TestRunButton />
      </Grid>
      <Grid item xs={12}>
        <Introduction />
      </Grid>
      <Grid item xs={12}>
        <IdentificationQuestionTemplate />
      </Grid>
      <Grid item xs={12}>
        <ExtraQuestionsSection />
      </Grid>
      <Grid item xs={12}>
        {elicitationMethod !== 'ranking' &&
        elicitationMethod !== 'choice' &&
        elicitationMethod !== 'threshold' ? (
          <ElicitationTemplate />
        ) : (
          <></>
        )}
      </Grid>
      <Grid item xs={12}>
        <FinishSurveyMessage />
      </Grid>
    </Grid>
  );
}
