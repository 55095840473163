import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import {useContext} from 'react';
import {AppContext} from '../../AppContext/AppContext';
import Criterion from './Criterion/Criterion';

export default function Criteria() {
  const {effectsTableRows} = useContext(AppContext);

  return (
    <Grid item container spacing={2} xs={12}>
      <Grid item xs={12}>
        <Typography variant="h6">Edit criteria</Typography>
      </Grid>
      <Grid item xs={12}>
        <Table id="criteria-table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Criterion</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Units</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(effectsTableRows, (criterion) => {
              return <Criterion key={criterion.mcdaId} criterion={criterion} />;
            })}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}
