import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import {useContext} from 'react';
import {AppContext} from '../../../AppContext/AppContext';
import IRow from '../../../Interface/IRow';
import StepSizeRow from './StepSizeRow/StepSizeRow';

export default function StepSizeTable() {
  const {effectsTableRows} = useContext(AppContext);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6">Slider step sizes</Typography>
      </Grid>
      <Grid item xs={12}>
        <Table id="matching-step-size-table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Criterion</TableCell>
              <TableCell align="center">Min</TableCell>
              <TableCell align="center">Max</TableCell>
              <TableCell align="center">Stepsize</TableCell>
              <TableCell align="center">Test slider</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(effectsTableRows, (row: IRow) => {
              return <StepSizeRow key={row.mcdaId} row={row} />;
            })}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}
