import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import axios, {AxiosError} from 'axios';
import {useContext} from 'react';
import {AppContext} from '../../../AppContext/AppContext';
import {ErrorContext} from '../../../Error/ErrorContext';
import TemplateButtons from '../TemplateButtons/TemplateButtons';

export default function IdentificationQuestionTemplate() {
  const {id, identificationQuestion, setIdentificationQuestion} =
    useContext(AppContext);
  const {setError} = useContext(ErrorContext);

  function saveIdentificationQuestion(question: string) {
    setIdentificationQuestion(question);
    updateQuestion(question);
  }

  function updateQuestion(question: string) {
    axios
      .put(`/api/v1/survey/${id}/identificationQuestion`, {
        question: question
      })
      .catch((error: AxiosError) => {
        setError(error.message + ', ' + error.response!.data);
      });
  }

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">
          Question asked to identify the surveyee:
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        id="identification-question"
        dangerouslySetInnerHTML={{__html: identificationQuestion}}
        className="margin-bottom template"
      />
      <Grid item xs={12}>
        <TemplateButtons
          id="identification-question-template-edit-button"
          templateType={'identificationQuestion'}
          saveCallback={saveIdentificationQuestion}
        />
      </Grid>
    </Grid>
  );
}
