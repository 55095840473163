import _ from 'lodash';
import ICriterion from '../Interface/ICriterion';
import IPvf from '../Interface/IPvf';
import IRow from '../Interface/IRow';
import IElicitationCriterion from '../Interface/PreferenceElicitation/IElicitationCriterion';
import IElicitationPvf from '../Interface/PreferenceElicitation/IElicitationPvf';
import {ElicitationMethod} from '../Type/ElicitationMethod';
import {determineStepSizes} from '../util/determineStepSizes';
import significantDigits from '../util/significantDigits';

export function buildPvf(criterion: ICriterion): IPvf {
  return {
    type: 'linear',
    direction: criterion.pvfDirection!,
    rowMCDAId: criterion.mcdaId,
    rowDatabaseId: criterion.databaseId
  };
}

export function mapElicitationCriteria(
  criteria: Record<string, ICriterion>
): IElicitationCriterion[] {
  return _.map(criteria, (criterion: ICriterion): IElicitationCriterion => {
    return {
      id: criterion.mcdaId,
      title: criterion.title,
      description: criterion.description ? criterion.description : '',
      dataSources: [
        {
          unitOfMeasurement: {
            type: criterion.unitOfMeasurementType,
            label: criterion.unitOfMeasurement
          }
        }
      ]
    };
  });
}

export function buildPvfs(
  criteria: Record<string, ICriterion>
): Record<string, IElicitationPvf> {
  return _.mapValues(criteria, (criterion: ICriterion): IElicitationPvf => {
    if (criterion.pvfDirection) {
      return {
        range: normalizeScales(criterion),
        direction: criterion.pvfDirection
      };
    } else {
      throw {message: 'Pvf not set.'};
    }
  });
}

function normalizeScales(criterion: ICriterion): [number, number] {
  if (criterion.unitOfMeasurementType === 'percentage') {
    return [
      significantDigits(criterion.scales[0] / 100),
      significantDigits(criterion.scales[1] / 100)
    ];
  } else {
    return criterion.scales;
  }
}

export function buildCriteria(
  effectsTableRows: IRow[] | undefined
): Record<string, ICriterion> {
  return _(effectsTableRows)
    .keyBy('mcdaId')
    .mapValues((row: IRow): ICriterion => {
      return _.omit(row, ['values']);
    })
    .value();
}

export function mapStepSizes(
  criteria: Record<string, ICriterion>
): Record<string, number> {
  return _.mapValues(criteria, (criterion: ICriterion): number =>
    normalizeStepSize(criterion)
  );
}

function normalizeStepSize(criterion: ICriterion): number {
  const stepSize = criterion.stepSize
    ? criterion.stepSize
    : determineStepSizes(criterion.scales)[1];
  return criterion.unitOfMeasurementType === 'percentage'
    ? significantDigits(stepSize / 100)
    : stepSize;
}

export function isPublicElicitationView(
  activeView: ElicitationMethod
): boolean {
  return (
    activeView === 'precise' ||
    activeView === 'matching' ||
    activeView === 'ranking'
  );
}

export function isPrivateElicitationView(
  activeView: ElicitationMethod
): boolean {
  return activeView === 'choice' || activeView === 'threshold';
}

export function getThresholdValues(
  criteria: Record<string, ICriterion>,
  inputValuesByCriterion: Record<string, number> | undefined
): Record<string, number> {
  return _.mapValues(
    inputValuesByCriterion,
    (inputValue: number, criterionId: string) =>
      criteria[criterionId].unitOfMeasurementType === 'percentage'
        ? inputValue / 100
        : inputValue
  );
}

export function calculateTotalCbmSteps(
  criteria: IRow[] | Record<string, ICriterion>
): number {
  if (_.size(criteria) <= 4) {
    return (_.size(criteria) - 1) * 4;
  } else {
    return (_.size(criteria) - 2) * 4;
  }
}
