import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import {useContext} from 'react';
import {AppContext} from '../../AppContext/AppContext';
import significantDigits from '../../util/significantDigits';
import PVFCell from './PVFCell/PVFCell';

export default function PVFSettings() {
  const {effectsTableRows} = useContext(AppContext);

  function createPVFRows() {
    return _.map(effectsTableRows, (criterion) => {
      return (
        <TableRow key={criterion.mcdaId}>
          <TableCell id={`criterion-title-${criterion.mcdaId}`}>
            {criterion.title}
          </TableCell>
          <TableCell id={`lowest-value-${criterion.mcdaId}`}>
            {significantDigits(criterion.scales[0])}
          </TableCell>
          <TableCell id={`highest-value-${criterion.mcdaId}`}>
            {significantDigits(criterion.scales[1])}
          </TableCell>
          <TableCell>
            <PVFCell criterion={criterion} />
          </TableCell>
        </TableRow>
      );
    });
  }

  return (
    <Grid item container spacing={2} xs={12}>
      <Grid item xs={12}>
        <Typography id="preset-pvfs-header" variant="h6">
          Please preset the partial value functions:{' '}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Table id="pvfs-table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Criterion</TableCell>
              <TableCell>Lowest value</TableCell>
              <TableCell>Highest value</TableCell>
              <TableCell>Best value setting</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{createPVFRows()}</TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}
