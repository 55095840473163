import {Button, ButtonGroup, Grid, Paper, TextField} from '@material-ui/core';
import _ from 'lodash';
import {ChangeEvent, useContext, useEffect, useState} from 'react';
import {AppContext} from '../../AppContext/AppContext';
import IExtraQuestion from '../../Interface/IExtraQuestion';
import {SurveyContext} from '../../SurveyContext/SurveyContext';

export default function AskExtraQuestions() {
  const {extraQuestions} = useContext(AppContext);
  const {currentStep, extraAnswers, setCurrentStep, setExtraAnswer} =
    useContext(SurveyContext);

  const [isAnswerMissing, setIsAnswerMissing] = useState(true);

  useEffect(() => {
    setIsAnswerMissing(_.some(extraAnswers, _.isEmpty));
  }, [extraAnswers]);

  function handleInputChange(
    id: string,
    event: ChangeEvent<HTMLTextAreaElement>
  ) {
    setExtraAnswer(id, event.target.value);
  }

  function handleNextClick() {
    setCurrentStep(currentStep + 1);
  }

  function handlePreviousClick() {
    setCurrentStep(currentStep - 1);
  }

  return (
    <Grid container item spacing={2} sm={12} md={6} component={Paper}>
      {_.map(
        extraQuestions,
        (extraQuestion: IExtraQuestion): JSX.Element => (
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              multiline
              fullWidth
              label={extraQuestion.question}
              value={extraAnswers[extraQuestion.id]}
              onChange={_.partial(handleInputChange, extraQuestion.id)}
            />
          </Grid>
        )
      )}
      <Grid item xs={9}>
        <ButtonGroup variant="contained" size="medium" color="primary">
          <Button id="previous-button" onClick={handlePreviousClick}>
            Previous
          </Button>
          <Button
            id="next-button"
            disabled={isAnswerMissing}
            onClick={handleNextClick}
          >
            Next
          </Button>
        </ButtonGroup>
      </Grid>
    </Grid>
  );
}
