import _ from 'lodash';
import {createContext, useState} from 'react';
import {
  DEFAULT_FINISH_SURVEY_MESSAGE,
  DEFAULT_IDENTIFICATION_QUESTION,
  DEFAULT_INTRODUCTION,
  DEFAULT_MATCHING_TEMPLATE,
  DEFAULT_PRECISE_TEMPLATE
} from '../constants';
import IAlternative from '../Interface/IAlternative';
import IAppContext from '../Interface/IAppContext';
import IExtraQuestion from '../Interface/IExtraQuestion';
import IRow from '../Interface/IRow';
import {ElicitationMethod} from '../Type/ElicitationMethod';
import {SurveyStatus} from '../Type/SurveyStatus';
import {TemplateType} from '../Type/TemplateType';

export const AppContext = createContext<IAppContext>({} as IAppContext);

export function AppContextProviderComponent({children}: {children: any}) {
  const [isUserOwner, setIsUserOwner] = useState(false);
  const [id, setId] = useState<string>('');
  const [hostUrl, setHostUrl] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [alternatives, setAlternatives] = useState<IAlternative[]>([]);
  const [effectsTableRows, setRows] = useState<IRow[]>([]);
  const [therapeuticContext, setTherapeuticContext] = useState<string>('');
  const [introduction, setIntroduction] = useState<string>('');
  const [elicitationMethod, setElicitationMethod] =
    useState<ElicitationMethod>('ranking');
  const [elicitationTemplate, setElicitationTemplate] = useState<string>('');
  const [identificationQuestion, setIdentificationQuestion] =
    useState<string>('');
  const [finishSurveyMessage, setFinishSurveyMessage] = useState<string>('');
  const [status, setStatus] = useState<SurveyStatus>('created');
  const [showCbmPieChart, setShowCbmPieChart] = useState(false);
  const [areExtraQuestionsOn, setAreExtraQuestionsOn] = useState(false);
  const [extraQuestions, setExtraQuestions] = useState<
    Record<string, IExtraQuestion>
  >({});

  const defaultTemplates: Record<string, string> = {
    precise: DEFAULT_PRECISE_TEMPLATE,
    matching: DEFAULT_MATCHING_TEMPLATE,
    introduction: DEFAULT_INTRODUCTION,
    identificationQuestion: DEFAULT_IDENTIFICATION_QUESTION,
    finishSurveyMessage: DEFAULT_FINISH_SURVEY_MESSAGE
  };

  function setAlternative(newAlternative: IAlternative) {
    const index = _.findIndex(alternatives, ['id', newAlternative.id]);
    let alternativesCopy = _.cloneDeep(alternatives);
    alternativesCopy![index] = newAlternative;
    setAlternatives(alternativesCopy);
  }

  function setRow(newRow: IRow) {
    const index = _.findIndex(effectsTableRows, ['mcdaId', newRow.mcdaId]);
    let effectsTableRowsCopy = _.cloneDeep(effectsTableRows);
    effectsTableRowsCopy![index] = newRow;
    setRows(effectsTableRowsCopy);
  }

  function isPublishingDisabled(): boolean {
    return _.some(effectsTableRows, (row) => {
      return !row.pvfDirection;
    });
  }

  function getTemplateByType(type: TemplateType): string {
    switch (type) {
      case 'matching':
        return elicitationTemplate;
      case 'precise':
        return elicitationTemplate;
      case 'introduction':
        return introduction;
      case 'identificationQuestion':
        return identificationQuestion;
      case 'finishSurveyMessage':
        return finishSurveyMessage;
    }
  }

  function getDefaultTemplateByType(type: TemplateType): string {
    return defaultTemplates[type];
  }

  function addQuestion(newQuestion: IExtraQuestion): void {
    const newQuestions = {...extraQuestions, [newQuestion.id]: newQuestion};
    setExtraQuestions(newQuestions);
  }

  function deleteQuestion(toDeleteId: string): void {
    const newQuestions = _.omit(extraQuestions, [toDeleteId]);
    setExtraQuestions(newQuestions);
  }

  function editQuestion(updatedQuestion: IExtraQuestion): void {
    const newQuestions = {
      ...extraQuestions,
      [updatedQuestion.id]: updatedQuestion
    };
    setExtraQuestions(newQuestions);
  }

  return (
    <AppContext.Provider
      value={{
        isUserOwner,
        id,
        hostUrl,
        status,
        title,
        therapeuticContext,
        introduction,
        alternatives,
        effectsTableRows,
        elicitationMethod,
        elicitationTemplate,
        identificationQuestion,
        finishSurveyMessage,
        showCbmPieChart,
        areExtraQuestionsOn,
        extraQuestions,
        setIsUserOwner,
        setId,
        setHostUrl,
        setStatus,
        setTitle,
        setTherapeuticContext,
        setAlternatives,
        setRows,
        setAlternative,
        setRow,
        setIntroduction,
        setElicitationMethod,
        isPublishingDisabled,
        setElicitationTemplate,
        setIdentificationQuestion,
        getTemplateByType,
        getDefaultTemplateByType,
        setFinishSurveyMessage,
        setShowCbmPieChart,
        setAreExtraQuestionsOn,
        setExtraQuestions,
        addQuestion,
        deleteQuestion,
        editQuestion
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
