import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import _ from 'lodash';
import {useContext} from 'react';
import {AppContext} from '../../../../../AppContext/AppContext';
import IExtraQuestion from '../../../../../Interface/IExtraQuestion';
import ShowIf from '../../../../../util/ShowIf/ShowIf';
import ExtraQuestionsTableRow from './ExtraQuestionsRow';

export default function ExtraQuestionsTable() {
  const {extraQuestions, status} = useContext(AppContext);
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Column header</TableCell>
          <TableCell>Question</TableCell>
          <ShowIf condition={status === 'created'}>
            <TableCell />
            <TableCell />
          </ShowIf>
        </TableRow>
      </TableHead>
      <TableBody>
        {_.map(
          _.values(extraQuestions),
          (extraQuestion: IExtraQuestion, index: number) => (
            <ExtraQuestionsTableRow
              extraQuestion={extraQuestion}
              index={index}
            />
          )
        )}
      </TableBody>
    </Table>
  );
}
