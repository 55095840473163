import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import {useContext} from 'react';
import {AppContext} from '../../../AppContext/AppContext';

export default function TestRunButton() {
  const {id, isPublishingDisabled} = useContext(AppContext);

  return (
    <Grid item container spacing={2} xs={12}>
      <Grid item xs={12}>
        During a test run, you can preview the survey as if it was displayed to
        a surveyee. You can also edit the templates and other content. To enable
        editing, click on the 'Show edit buttons' button at the top of the
        screen during the test run.
      </Grid>
      <Grid item xs={12}>
        <Tooltip
          title="Not all PVFs are set"
          disableHoverListener={!isPublishingDisabled()}
        >
          <span>
            <Button
              id="start-test-run-button"
              href={`/survey/${id}/testRun`}
              color="primary"
              disabled={isPublishingDisabled()}
              variant="contained"
            >
              Test run
            </Button>
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  );
}
