import _ from 'lodash';
import {createContext, useContext, useState} from 'react';
import {AppContext} from '../AppContext/AppContext';
import ICriterion from '../Interface/ICriterion';
import IExtraQuestion from '../Interface/IExtraQuestion';
import ISurveyContext from '../Interface/ISurveyContext';
import {IFinishedElicitation} from '../Interface/PreferenceElicitation/IFinishedElicitation';

export const SurveyContext = createContext<ISurveyContext>(
  {} as ISurveyContext
);

export function SurveyContextProviderComponent({
  children,
  inputCriteria,
  finishCallback
}: {
  children: any;
  inputCriteria: Record<string, ICriterion>;
  finishCallback: (finishedElicitation: IFinishedElicitation) => Promise<any>;
}) {
  const {extraQuestions} = useContext(AppContext);
  const [criteria, setCriteria] =
    useState<Record<string, ICriterion>>(inputCriteria);
  const [name, setName] = useState<string>('');
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [isSurveyDone, setIsSurveyDone] = useState<boolean>(false);
  const [extraAnswers, setExtraAnswers] = useState<Record<string, string>>(
    _.mapValues(extraQuestions, (_q: IExtraQuestion) => '')
  );

  const firstElicitationStep = 2;

  function updateCriterion(criterion: ICriterion) {
    let updatedCriteria = _.cloneDeep(criteria);
    updatedCriteria[criterion.mcdaId] = criterion;
    setCriteria(updatedCriteria);
  }

  function setRanking(criterion: ICriterion, ranking: number) {
    const updatedCriterion = {
      ...criterion,
      rank: ranking
    };
    updateCriterion(updatedCriterion);
  }

  function setImportance(criterion: ICriterion, importance: number) {
    const updatedCriterion: ICriterion = {
      ...criterion,
      importance: importance
    };
    updateCriterion(updatedCriterion);
  }

  function initImportances() {
    const updatedCriteria = _.mapValues(criteria, (criterion) => {
      return {...criterion, importance: 100};
    });
    setCriteria(updatedCriteria);
  }

  function setExtraAnswer(questionId: string, newAnswer: string): void {
    const newAnswers = {...extraAnswers, [questionId]: newAnswer};
    setExtraAnswers(newAnswers);
  }

  return (
    <SurveyContext.Provider
      value={{
        criteria,
        name,
        currentStep,
        firstElicitationStep,
        isSurveyDone,
        extraAnswers,
        setRanking,
        setName: setName,
        setCriteria,
        setExtraAnswer,
        setImportance,
        setIsSurveyDone,
        initImportances,
        finishCallback,
        setCurrentStep
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
}
