import {Button} from '@material-ui/core';
import FileCopy from '@material-ui/icons/FileCopy';
import ClipboardJS from 'clipboard';
import {useContext} from 'react';
import {AppContext} from '../../AppContext/AppContext';

export default function SurveyLink() {
  new ClipboardJS('.clipboard');

  const {id, hostUrl} = useContext(AppContext);
  const surveyLink = generateSurveyLink();

  function generateSurveyLink() {
    const surveyId = id;
    return `${hostUrl}/survey/${surveyId}`;
  }

  return (
    <span>
      <b>The survey is published at: </b>
      <span id="survey-link">{surveyLink}</span>{' '}
      <Button
        className="clipboard"
        color="primary"
        variant="contained"
        size="small"
        data-clipboard-target="#survey-link"
        style={{marginBottom: '5px'}}
      >
        <FileCopy /> Copy to clipboard
      </Button>
    </span>
  );
}
