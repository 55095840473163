import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {AppContext} from '../../AppContext/AppContext';

export default function SurveyTabs({surveyId}: {surveyId: string}) {
  const [activeTab, setActiveTab] = useState('problemDefinition');
  const {status, isPublishingDisabled} = useContext(AppContext);

  const history = useHistory();

  useEffect(() => {
    const path = history.location.pathname;
    const tab = path.split('/overview/')[1];
    setActiveTab(tab);
  }, [history, setActiveTab]);

  function handleChange(event: any, tab: string) {
    setActiveTab(tab);
    history.push(`/survey/${surveyId}/overview/${tab}`);
  }

  return (
    <Tabs
      value={activeTab}
      onChange={handleChange}
      indicatorColor="primary"
      variant="scrollable"
      scrollButtons="auto"
    >
      <Tab id="problem-tab" label="Problem" value="problemDefinition" />
      <Tab id="criteria-tab" label="Criteria" value="criteria" />
      <Tab id="pvf-tab" label="Partial Value Functions" value="pvfs" />
      <Tab
        id="elicitation-method-tab"
        label="Elicitation Method"
        value="elicitationMethod"
      />
      <Tab
        id="customization-tab"
        label="Customize and Test"
        value="templates"
      />
      <Tab
        id="publish-tab"
        style={{pointerEvents: 'auto'}}
        label={
          <Tooltip
            title="Not all PVFs are set"
            disableHoverListener={!isPublishingDisabled()}
          >
            <span>Publish</span>
          </Tooltip>
        }
        value="publish"
        disabled={isPublishingDisabled()}
      />
      <Tab
        id="responses-tab"
        style={{pointerEvents: 'auto'}}
        label={
          <Tooltip
            title="Survey is not published"
            disableHoverListener={status !== 'created'}
          >
            <span>Responses</span>
          </Tooltip>
        }
        value="responses"
        disabled={status === 'created'}
      />
    </Tabs>
  );
}
