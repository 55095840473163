import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import Axios, {AxiosError, AxiosResponse} from 'axios';
import {ChangeEvent, useContext, useState} from 'react';
import {AppContext} from '../../../../../AppContext/AppContext';
import DialogTitleWithCross from '../../../../../DialogTitleWithCross/DialogTitleWithCross';
import {ErrorContext} from '../../../../../Error/ErrorContext';
import IExtraQuestion from '../../../../../Interface/IExtraQuestion';
import AsyncSingleClickButton from '../../../../../util/AsyncSingleClickButton/AsyncSingleClickButton';

export default function AddExtraQuestionDialog({
  isDialogOpen,
  closeDialog
}: {
  isDialogOpen: boolean;
  closeDialog: () => void;
}): JSX.Element {
  const {setError} = useContext(ErrorContext);
  const {id, addQuestion} = useContext(AppContext);

  const [question, setQuestion] = useState<string>('');
  const [columnHeader, setColumnHeader] = useState<string>('');

  function handleQuestionChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void {
    setQuestion(event.target.value);
  }

  function handleColumnHeaderChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void {
    setColumnHeader(event.target.value);
  }

  function handleAddClick(): Promise<void> {
    return Axios.post(`/api/v1/survey/${id}/extraQuestion`, {
      question: question,
      columnHeader: columnHeader
    })
      .then((result: AxiosResponse<string>) => {
        const newQuestion: IExtraQuestion = {
          id: result.data,
          question: question,
          columnHeader: columnHeader
        };
        addQuestion(newQuestion);
        cleanup();
        closeDialog();
      })
      .catch((error: AxiosError) => {
        cleanup();
        setError(error.message + ', ' + error.response!.data);
      });
  }

  function cleanup() {
    setQuestion('');
    setColumnHeader('');
  }

  return (
    <Dialog open={isDialogOpen} onClose={closeDialog} fullWidth maxWidth={'md'}>
      <DialogTitleWithCross
        id="add-extra-question-dialog"
        onClose={closeDialog}
      >
        Add additional survey question
      </DialogTitleWithCross>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography>Column header</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="header-input"
              variant="outlined"
              value={columnHeader}
              onChange={handleColumnHeaderChange}
              fullWidth
              helperText={!columnHeader ? 'No value entered' : ''}
              error={!columnHeader}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Question</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="question-input"
              variant="outlined"
              value={question}
              onChange={handleQuestionChange}
              rows="3"
              multiline
              fullWidth
              helperText={!question ? 'No value entered' : ''}
              error={!question}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container xs={12} justify="flex-start" spacing={2}>
          <Grid item>
            <AsyncSingleClickButton
              color="primary"
              variant="contained"
              id="confirm-add-question-button"
              asyncFunction={handleAddClick}
              disabled={!question || !columnHeader}
            >
              Add
            </AsyncSingleClickButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
