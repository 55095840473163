import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import _ from 'lodash';
import {useContext} from 'react';
import {AppContext} from '../../../AppContext/AppContext';
import IExtraQuestion from '../../../Interface/IExtraQuestion';
import IResponse from '../../../Interface/IResponse';
import IRow from '../../../Interface/IRow';
import ResponseRow from './ResponseRow/ResponseRow';

export default function ResponseTable({
  responses,
  showExtraQuestions
}: {
  responses: IResponse[];
  showExtraQuestions: boolean;
}): JSX.Element {
  const {effectsTableRows, extraQuestions} = useContext(AppContext);

  function createCriteriaHeaders() {
    return _.map(effectsTableRows, (criterion: IRow) => {
      return (
        <TableCell
          id={`criterion-title-${criterion.mcdaId}`}
          key={criterion.mcdaId}
        >
          {criterion.title}
        </TableCell>
      );
    });
  }

  function createExtraQuestionsHeaders() {
    return _.map(
      extraQuestions,
      (extraQuestion: IExtraQuestion): JSX.Element => (
        <TableCell
          id={`extra-question-title-${extraQuestion.id}`}
          key={extraQuestion.id}
        >
          {extraQuestion.columnHeader}
        </TableCell>
      )
    );
  }

  function createResponseRows() {
    return _.map(
      responses,
      (response: IResponse): JSX.Element => (
        <ResponseRow
          key={response.name}
          response={response}
          showExtraQuestions={showExtraQuestions}
        />
      )
    );
  }

  return (
    <Table id="response-table" size="small">
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          {createCriteriaHeaders()}
          {showExtraQuestions ? createExtraQuestionsHeaders() : <></>}
        </TableRow>
      </TableHead>
      <TableBody>{createResponseRows()}</TableBody>
    </Table>
  );
}
