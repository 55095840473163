import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import axios, {AxiosError} from 'axios';
import {useContext} from 'react';
import {AppContext} from '../../../AppContext/AppContext';
import {ErrorContext} from '../../../Error/ErrorContext';
import ICriterion from '../../../Interface/ICriterion';
import IRow from '../../../Interface/IRow';
import EditFieldModal from '../../EditFieldModal/EditFieldModal';

export default function Criterion({criterion}: {criterion: IRow}) {
  const {id, setRow, status} = useContext(AppContext);
  const {setError} = useContext(ErrorContext);

  function handleTitleChange(newTitle: string) {
    const newRow = {...criterion, title: newTitle};
    updateRow(newRow);
  }

  function handleDescriptionChange(newDescription: string) {
    const newRow = {...criterion, description: newDescription};
    updateRow(newRow);
  }

  function handleUnitOfMeasurementChange(newUnitOfMeasurement: string) {
    const newRow = {...criterion, unitOfMeasurement: newUnitOfMeasurement};
    updateRow(newRow);
  }

  function updateRow(newRow: ICriterion) {
    setRow(newRow as IRow);
    axios
      .put(`/api/v1/survey/${id}/criterion/${newRow.databaseId}`, newRow)
      .catch((error: AxiosError) => {
        setError(error.message + ', ' + error.response!.data);
      });
  }

  return (
    <TableRow id={`criterion-row-${criterion.mcdaId}`}>
      <TableCell id={`criterion-title-${criterion.mcdaId}`}>
        {criterion.title}{' '}
        <EditFieldModal
          buttonId={`edit-criterion-title-${criterion.mcdaId}`}
          initialValue={criterion.title}
          saveCallback={handleTitleChange}
          propertyName="criterion"
          propertyField="Title"
          fieldType="input"
          visible={status === 'created'}
          cantBeEmpty={true}
        />
      </TableCell>
      <TableCell id={`criterion-description-${criterion.mcdaId}`}>
        {criterion.description}{' '}
        <EditFieldModal
          buttonId={`edit-criterion-description-${criterion.mcdaId}`}
          initialValue={criterion.description}
          saveCallback={handleDescriptionChange}
          propertyName="criterion"
          propertyField="Description"
          fieldType="textArea"
          visible={status === 'created'}
        />
      </TableCell>
      <TableCell id={`criterion-unit-${criterion.mcdaId}`}>
        {criterion.unitOfMeasurement}{' '}
        <EditFieldModal
          buttonId={`edit-criterion-unit-${criterion.mcdaId}`}
          initialValue={criterion.unitOfMeasurement}
          saveCallback={handleUnitOfMeasurementChange}
          propertyName="criterion"
          propertyField="Unit of measurement"
          fieldType="input"
          visible={status === 'created'}
        />
      </TableCell>
    </TableRow>
  );
}
