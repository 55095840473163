import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import {useContext, useState} from 'react';
import {AppContext} from '../../AppContext/AppContext';
import EditTemplatesDialog from './EditTemplatesDialog/EditTemplatesDialog';

export default function TestRunButtons() {
  const {id, status} = useContext(AppContext);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  function openDialog(): void {
    setIsDialogOpen(true);
  }

  function closeDialog(): void {
    setIsDialogOpen(false);
  }

  function getEditIcon() {
    if (status === 'created') {
      return (
        <Tooltip title="Edit templates.">
          <Fab id="edit-button" color="primary" onClick={openDialog}>
            <EditIcon />
          </Fab>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="The survey is published and cannot be edited">
          <Fab id="edit-button-disabled" color="default">
            <EditIcon color="disabled" />
          </Fab>
        </Tooltip>
      );
    }
  }

  return (
    <>
      <Grid item container xs={12} justify="center">
        <Grid item sm={12} md={6} container justify="flex-end">
          <Grid
            item
            container
            component={Box}
            mt={2}
            spacing={1}
            justify="flex-end"
          >
            <Grid item>{getEditIcon()}</Grid>
            <Grid item>
              <Tooltip title="Stop test run">
                <Fab
                  id="stop-test-run-button"
                  color="secondary"
                  href={`/survey/${id}/overview/templates`}
                >
                  <CloseIcon htmlColor="white" />
                </Fab>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <EditTemplatesDialog
        isDialogOpen={isDialogOpen}
        closeDialog={closeDialog}
      />
    </>
  );
}
