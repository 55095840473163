import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {useState} from 'react';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export default function InlineEditor({
  template,
  defaultTemplate,
  callback,
  resetCallback
}: {
  template: string;
  defaultTemplate: string;
  callback: (content: string) => void;
  resetCallback: () => void;
}) {
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(buildContentState(template))
  );
  const [isHelpVisible, setIsHelpVisible] = useState<boolean>(false);

  function buildContentState(template: string): ContentState {
    const blocksFromHtml = htmlToDraft(template);
    const {contentBlocks, entityMap} = blocksFromHtml;
    return ContentState.createFromBlockArray(contentBlocks, entityMap);
  }

  function handleChange(newState: EditorState) {
    const contentAsHtml = draftToHtml(
      convertToRaw(newState.getCurrentContent())
    );
    callback(contentAsHtml);
    setEditorState(newState);
  }

  function handleReset() {
    setEditorState(
      EditorState.createWithContent(buildContentState(defaultTemplate))
    );
    resetCallback();
  }

  function Default() {
    return (
      <Button onClick={handleReset} size="small">
        Default
      </Button>
    );
  }

  function Help() {
    return (
      <IconButton onClick={toggleHelp} size="small">
        <HelpIcon />
      </IconButton>
    );
  }

  function toggleHelp() {
    setIsHelpVisible(!isHelpVisible);
  }

  return (
    <>
      <Grid item xs={12}>
        <Editor
          editorState={editorState}
          onEditorStateChange={handleChange}
          editorStyle={{border: 'solid 1px #f1f1f1'}}
          wrapperStyle={{marginBottom: 5}}
          toolbarCustomButtons={[<Default />, <Help />]}
        />
      </Grid>
      {isHelpVisible ? (
        <Grid item xs={12}>
          The following variables are available:
          <ul>
            <li>%criterion1%: most important or the only criterion</li>
            <li>%unit1%</li>
            <li>%best1%</li>
            <li>%worst1%</li>
            <li>%criterion2%: second criterion, e.g. during matching</li>
            <li>%unit2%</li>
            <li>%best2%</li>
            <li>%worst2%</li>
          </ul>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}
