import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

export default function SurveyIsClosedScreen() {
  return (
    <Grid container justify="center" component={Box} mt={2}>
      <Grid container item sm={12} md={8} spacing={2} component={Paper}>
        <Grid item xs={12}>
          The survey is closed and does not accept new responses.
        </Grid>
      </Grid>
    </Grid>
  );
}
