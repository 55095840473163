import Select from '@material-ui/core/Select';
import Axios, {AxiosError} from 'axios';
import {useContext} from 'react';
import {AppContext} from '../../../AppContext/AppContext';
import {ErrorContext} from '../../../Error/ErrorContext';
import IRow from '../../../Interface/IRow';

export default function PVFCell({criterion}: {criterion: IRow}) {
  const {status, setRow, id} = useContext(AppContext);
  const {setError} = useContext(ErrorContext);

  function handleDirectionChange(event: any) {
    const newRow = {
      ...criterion,
      pvfDirection: event.target.value
    };
    setRow(newRow);
    Axios.put(
      `/api/v1/survey/${id}/criterion/${newRow.databaseId}`,
      newRow
    ).catch((error: AxiosError) => {
      setError(error.message + ', ' + error.response!.data);
    });
  }

  return (
    <Select
      native
      id={`criterion-pvf-direction-selector-${criterion.mcdaId}`}
      value={criterion.pvfDirection ? criterion.pvfDirection : ''}
      onChange={handleDirectionChange}
      disabled={status !== 'created'}
      displayEmpty
      style={{minWidth: 220}}
    >
      <option value="" disabled>
        Please select an option
      </option>
      <option label="higher" value={'increasing'}>
        Higher is better
      </option>
      <option label="lower" value={'decreasing'}>
        Lower is better
      </option>
    </Select>
  );
}
