import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import axios, {AxiosError} from 'axios';
import {useContext, useState} from 'react';
import {AppContext} from '../../../AppContext/AppContext';
import DialogTitleWithCross from '../../../DialogTitleWithCross/DialogTitleWithCross';
import {ErrorContext} from '../../../Error/ErrorContext';
import {SurveyStatus} from '../../../Type/SurveyStatus';

export default function CloseSurveyButton() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const {id, setStatus, status} = useContext(AppContext);
  const {setError} = useContext(ErrorContext);

  function toggleDialog() {
    setIsDialogOpen(!isDialogOpen);
  }

  function handleCloseSurveyClick() {
    updateStatus('closed');
    setStatus('closed');
    toggleDialog();
  }

  function updateStatus(status: SurveyStatus) {
    axios
      .put(`/api/v1/survey/${id}/status`, {status: status})
      .catch((error: AxiosError) => {
        setError(error.message + ', ' + error.response!.data);
      });
  }

  return (
    <span>
      <Button
        id="close-survey-button"
        onClick={toggleDialog}
        color="primary"
        disabled={status !== 'published'}
        variant="contained"
      >
        Close Survey
      </Button>

      <Dialog
        open={isDialogOpen}
        onClose={toggleDialog}
        fullWidth
        maxWidth={'sm'}
      >
        <DialogTitleWithCross id="close-survey-dialog" onClose={toggleDialog}>
          Close survey
        </DialogTitleWithCross>
        <DialogContent>
          Are you sure you want to close this survey? You will be able to reopen
          the survey again.
        </DialogContent>
        <DialogActions>
          <Grid container xs={12} justify="flex-start" spacing={2}>
            <Grid item>
              <Button
                id="confirm-closing-button"
                color="primary"
                onClick={handleCloseSurveyClick}
                variant="contained"
              >
                Close survey
              </Button>
            </Grid>
            <Grid item>
              <Button
                id="cancel-closing-button"
                onClick={toggleDialog}
                color="secondary"
                variant="contained"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </span>
  );
}
