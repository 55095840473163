import {BrowserRouter as Router, Route} from 'react-router-dom';
import {AppContextProviderComponent} from './AppContext/AppContext';
import ContextSetter from './ContextSetter';
import {ErrorContextProviderComponent} from './Error/ErrorContext';
import ErrorHandler from './Error/ErrorHandler';
import './mcda-survey.css';

export default function App() {
  return (
    <ErrorContextProviderComponent>
      <ErrorHandler>
        <AppContextProviderComponent>
          <Router>
            <Route path="/survey/:surveyId" component={ContextSetter} />
          </Router>
        </AppContextProviderComponent>
      </ErrorHandler>
    </ErrorContextProviderComponent>
  );
}
