import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import axios, {AxiosError} from 'axios';
import _ from 'lodash';
import {useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {AppContext} from '../AppContext/AppContext';
import {buildCriteria, buildPvf} from '../Elicitation/ElicitationUtil';
import {ErrorContext} from '../Error/ErrorContext';
import IFinishedElicitationCommand from '../Interface/Commands/IFinishedElicitationCommand';
import {IFinishedElicitation} from '../Interface/PreferenceElicitation/IFinishedElicitation';
import Survey from '../Survey/Survey';
import {SurveyContextProviderComponent} from '../SurveyContext/SurveyContext';

export default function TakeSurvey({
  match
}: {
  match: {params: {surveyId: string}};
}) {
  const {effectsTableRows, status, elicitationMethod} = useContext(AppContext);
  const {setError} = useContext(ErrorContext);
  const history = useHistory();

  if (status === 'created') {
    setError('Survey not available/yet published.');
  }

  if (status === 'closed') {
    history.push(`/survey/${match.params.surveyId}/takeSurvey/closed`);
  }

  function finishSurveyCallback({
    name,
    preferences,
    thresholdValuesByCriterion,
    extraAnswers
  }: IFinishedElicitation): Promise<any> {
    const postCommand: IFinishedElicitationCommand = {
      name,
      preferences,
      partialValueFunctions: _.map(buildCriteria(effectsTableRows), buildPvf),
      type: elicitationMethod,
      thresholdValuesByCriterion,
      extraAnswers
    };
    return axios
      .post(`/api/v1/survey/${match.params.surveyId}/response`, postCommand)
      .catch((error: AxiosError) => {
        setError(error.message + ', ' + error.response!.data);
      });
  }

  return (
    <SurveyContextProviderComponent
      inputCriteria={buildCriteria(effectsTableRows)}
      finishCallback={finishSurveyCallback}
    >
      <Grid container justify="center" component={Box} mt={2}>
        <Survey />
      </Grid>
    </SurveyContextProviderComponent>
  );
}
