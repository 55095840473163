import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import {useContext, useState} from 'react';
import {AppContext} from '../../../AppContext/AppContext';
import {TemplateType} from '../../../Type/TemplateType';
import EditTemplateModal from '../EditTemplateModal/EditTemplateModal';

export default function TemplateButtons({
  id,
  templateType,
  saveCallback
}: {
  id: string;
  templateType: TemplateType;
  saveCallback: (template: string) => void;
}) {
  const [isDialogOpen, setModalIsOpen] = useState(false);
  const {status} = useContext(AppContext);

  function toggleDialog() {
    setModalIsOpen(!isDialogOpen);
  }

  return (
    <Grid item container spacing={2}>
      <Grid item>
        <Tooltip
          title="Cannot edit templates when the survey is published"
          disableHoverListener={status === 'created'}
        >
          <span>
            <Button
              id={id}
              color="primary"
              onClick={toggleDialog}
              variant="contained"
              disabled={status !== 'created'}
            >
              Edit
            </Button>
          </span>
        </Tooltip>
      </Grid>
      <EditTemplateModal
        isDialogOpen={isDialogOpen}
        toggleDialog={toggleDialog}
        templateType={templateType}
        saveCallback={saveCallback}
      />
    </Grid>
  );
}
