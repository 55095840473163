import Select from '@material-ui/core/Select';
import Slider from '@material-ui/core/Slider';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Axios, {AxiosError} from 'axios';
import _ from 'lodash';
import {useCallback, useContext, useEffect, useState} from 'react';
import {AppContext} from '../../../../AppContext/AppContext';
import {ErrorContext} from '../../../../Error/ErrorContext';
import IRow from '../../../../Interface/IRow';
import {determineStepSizes} from '../../../../util/determineStepSizes';
import significantDigits from '../../../../util/significantDigits';

export default function StepSizeRow({row}: {row: IRow}) {
  const {setRow, status, id} = useContext(AppContext);
  const {setError} = useContext(ErrorContext);
  const stepSizeOptions = determineStepSizes(row.scales);
  const [stepSize, setStepSize] = useState<number>(0);
  const [sliderValue, setSliderValue] = useState<number>(
    Math.min(...row.scales)
  );

  const updateRow = useCallback(
    (newStepSize: number) => {
      const newRow = {...row, stepSize: newStepSize};
      setStepSize(newStepSize);
      setRow(newRow);
      Axios.put(
        `/api/v1/survey/${id}/criterion/${newRow.databaseId}`,
        newRow
      ).catch((error: AxiosError) => {
        setError(error.message + ', ' + error.response!.data);
      });
    },
    [id, row, setError, setRow]
  );

  useEffect(() => {
    if (!row.stepSize) {
      if (status === 'created') {
        updateRow(stepSizeOptions[0]);
      }
    } else {
      setStepSize(row.stepSize);
    }
  }, [row.stepSize, stepSizeOptions, updateRow, status]);

  function handleChange(event: any) {
    updateRow(Number.parseFloat(event.target.value));
  }

  function handleSliderChanged(event: any, newValue: any) {
    setSliderValue(newValue);
  }

  return (
    <TableRow>
      <TableCell id={`criterion-title-${row.mcdaId}`}>{row.title}</TableCell>
      <TableCell id={`minimum-${row.mcdaId}`} align="center">
        {significantDigits(row.scales[0])}
      </TableCell>
      <TableCell id={`maximum-${row.mcdaId}`} align="center">
        {significantDigits(row.scales[1])}
      </TableCell>
      <TableCell align="center">
        <Select
          native
          id={`step-size-selector-${row.mcdaId}`}
          value={stepSize}
          onChange={handleChange}
          disabled={status !== 'created'}
          style={{minWidth: 100}}
        >
          {_.map(stepSizeOptions, (option) => {
            return (
              <option value={option}>{significantDigits(option, 1)}</option>
            );
          })}
        </Select>
      </TableCell>
      <TableCell align="center">
        {significantDigits(sliderValue)}
        <Slider
          id={`step-size-test-slider-${row.mcdaId}`}
          value={sliderValue}
          min={row.scales[0]}
          max={row.scales[1]}
          onChange={handleSliderChanged}
          step={stepSize}
        />
      </TableCell>
    </TableRow>
  );
}
