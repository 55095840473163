import {Button} from '@material-ui/core';
import {useContext, useState} from 'react';
import {AppContext} from '../../../../AppContext/AppContext';
import AddExtraQuestionDialog from './AddExtraQuestionDialog/AddExtraQuestionDialog';

export default function AddExtraQuestion(): JSX.Element {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const {status} = useContext(AppContext);

  function closeDialog(): void {
    setIsDialogOpen(false);
  }

  function openDialog(): void {
    setIsDialogOpen(true);
  }

  return (
    <>
      <Button
        id="add-extra-question-button"
        variant="contained"
        color="primary"
        onClick={openDialog}
        disabled={status !== 'created'}
      >
        Add question
      </Button>
      <AddExtraQuestionDialog
        isDialogOpen={isDialogOpen}
        closeDialog={closeDialog}
      />
    </>
  );
}
