import {Checkbox, FormControlLabel, Grid, Typography} from '@material-ui/core';
import axios, {AxiosError} from 'axios';
import {ChangeEvent, useContext} from 'react';
import {AppContext} from '../../../AppContext/AppContext';
import {calculateTotalCbmSteps} from '../../../Elicitation/ElicitationUtil';
import {ErrorContext} from '../../../Error/ErrorContext';

export default function ChoiceBasedMatchingWarningAndOptions(): JSX.Element {
  const {id, effectsTableRows, showCbmPieChart, setShowCbmPieChart} =
    useContext(AppContext);
  const {setError} = useContext(ErrorContext);

  function handleRadioChanged(event: ChangeEvent<HTMLInputElement>): void {
    const newSetting = event.target.checked;
    setShowCbmPieChart(newSetting);
    axios
      .put(`/api/v1/survey/${id}/showCbmPieChart`, {
        showCbmPieChart: newSetting
      })
      .catch((error: AxiosError) => {
        setError(error.message + ', ' + error.response?.data);
        setShowCbmPieChart(!newSetting);
      });
  }

  return (
    <>
      <Grid container item xs={12}>
        <FormControlLabel
          label="Show pie chart during choice-based matching"
          labelPlacement="start"
          control={
            <Checkbox
              id="cbm-pie-chart-toggle"
              checked={showCbmPieChart}
              onChange={handleRadioChanged}
              color="primary"
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Typography id="choice-based-matching-statement">
          {`Choice-based matching will result in ${calculateTotalCbmSteps(
            effectsTableRows
          )} questions being asked during
        the survey. This is based on the number of criteria.`}
        </Typography>
      </Grid>
    </>
  );
}
