import {Checkbox, Grid, Typography} from '@material-ui/core';
import axios, {AxiosError} from 'axios';
import {ChangeEvent, useContext} from 'react';
import {AppContext} from '../../../AppContext/AppContext';
import {ErrorContext} from '../../../Error/ErrorContext';
import ShowIf from '../../../util/ShowIf/ShowIf';
import AddExtraQuestion from './AddExtraQuestion/AddExtraQuestion';
import ExtraQuestions from './ExtraQuestions/ExtraQuestions';

export default function ExtraQuestionsSection(): JSX.Element {
  const {setError} = useContext(ErrorContext);
  const {
    id,
    areExtraQuestionsOn,
    status,
    setAreExtraQuestionsOn,
    setExtraQuestions
  } = useContext(AppContext);

  function handleCheckboxChanged(
    _event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    axios
      .put(`/api/v1/survey/${id}/areExtraQuestionsOn`, {
        areExtraQuestionsOn: checked
      })
      .then(() => {
        if (!checked) {
          deleteQuestions();
        }
      })
      .catch((error: AxiosError) => {
        setError(error.message + ', ' + error.response!.data);
      });

    setAreExtraQuestionsOn(checked);
  }

  function deleteQuestions(): void {
    setExtraQuestions({});
  }

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" display="inline">
          Ask additional questions during the survey:
        </Typography>
        <Checkbox
          id="optional-questions-toggle"
          checked={areExtraQuestionsOn}
          disabled={status !== 'created'}
          onChange={handleCheckboxChanged}
          color="primary"
        />
      </Grid>
      <ShowIf condition={areExtraQuestionsOn}>
        <Grid container item xs={12}>
          <ExtraQuestions />
          <AddExtraQuestion />
        </Grid>
      </ShowIf>
    </Grid>
  );
}
