import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {useContext} from 'react';
import {AppContext} from '../AppContext/AppContext';

export default function ThankYouScreen() {
  const {finishSurveyMessage} = useContext(AppContext);

  return (
    <Grid container item spacing={2} sm={12} md={6} component={Paper}>
      <Grid
        item
        xs={12}
        id="finish-survey-message"
        dangerouslySetInnerHTML={{__html: finishSurveyMessage}}
      />
    </Grid>
  );
}
