import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import {useContext, useEffect, useState} from 'react';
import {AppContext} from '../../../AppContext/AppContext';
import DialogTitleWithCross from '../../../DialogTitleWithCross/DialogTitleWithCross';
import InlineEditor from '../../../InlineEditor/InlineEditor';
import {TemplateType} from '../../../Type/TemplateType';

export default function EditTemplateModal({
  isDialogOpen,
  toggleDialog,
  templateType,
  saveCallback
}: {
  isDialogOpen: boolean;
  toggleDialog: () => void;
  templateType: TemplateType;
  saveCallback: (value: string) => void;
}) {
  const {getTemplateByType, getDefaultTemplateByType} = useContext(AppContext);
  const [inputTemplate, setInputTemplate] = useState(
    getTemplateByType(templateType)
  );

  useEffect(() => {
    setInputTemplate(getTemplateByType(templateType));
  }, [getTemplateByType, templateType, isDialogOpen]);

  function handleSave() {
    saveCallback(inputTemplate);
    toggleDialog();
  }

  function resetTemplate() {
    setInputTemplate(getDefaultTemplateByType(templateType));
  }

  return (
    <Dialog
      open={isDialogOpen}
      onClose={toggleDialog}
      fullWidth
      maxWidth={'lg'}
    >
      <DialogTitleWithCross id="edit-template-dialog" onClose={toggleDialog}>
        Edit template
      </DialogTitleWithCross>
      <DialogContent>
        <InlineEditor
          template={inputTemplate}
          defaultTemplate={getDefaultTemplateByType(templateType)}
          callback={setInputTemplate}
          resetCallback={resetTemplate}
        />
      </DialogContent>
      <DialogActions>
        <Grid container xs={12} justify="flex-start" spacing={2}>
          <Grid item>
            <Button
              id="save-template-button"
              color="primary"
              variant="contained"
              onClick={handleSave}
              disabled={!inputTemplate}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
