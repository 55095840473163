import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Axios, {AxiosError} from 'axios';
import {useContext, useState} from 'react';
import {AppContext} from '../../../AppContext/AppContext';
import {ErrorContext} from '../../../Error/ErrorContext';
import {TemplateType} from '../../../Type/TemplateType';
import TemplateButtons from '../TemplateButtons/TemplateButtons';

export default function ElicitationTemplate() {
  const {elicitationMethod, elicitationTemplate, setElicitationTemplate, id} =
    useContext(AppContext);
  const {setError} = useContext(ErrorContext);

  const [preview, setPreview] = useState(generatePreview(elicitationTemplate));

  function generatePreview(template: string): string {
    return template
      .replace(/%criterion1%/gi, 'CRITERION 1')
      .replace(/%unit1%/gi, 'UNIT OF CRITERION 1')
      .replace(/%worst1%/gi, 'WORST VALUE OF CRITERION 1')
      .replace(/%best1%/gi, 'BEST VALUE OF CRITERION 1')
      .replace(/%criterion2%/gi, 'CRITERION 2')
      .replace(/%unit2%/gi, 'UNIT OF CRITERION 2')
      .replace(/%worst2%/gi, 'WORST VALUE OF CRITERION 2')
      .replace(/%best2%/gi, 'BEST VALUE OF CRITERION 2');
  }

  function getTitle(): string | undefined {
    if (elicitationMethod === 'precise') {
      return 'Question during precise swing elicitation';
    } else if (elicitationMethod === 'matching') {
      return 'Question during matching elicitation';
    } else if (elicitationMethod === 'choice') {
      return 'Question during choice based matching elicitation';
    } else if (elicitationMethod === 'threshold') {
      return 'Question during threshold elicitation';
    } else {
      setError('Invalid elicitation method: ' + elicitationMethod);
    }
  }

  function saveTemplate(template: string): void {
    setPreview(generatePreview(template));
    setElicitationTemplate(template);
    Axios.put(`/api/v1/survey/${id}/elicitationTemplate`, {
      elicitationTemplate: template
    }).catch((error: AxiosError) => {
      setError(error.message + ', ' + error.response!.data);
    });
  }

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">{getTitle()}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        id="template-preview"
        dangerouslySetInnerHTML={{__html: preview}}
        className="margin-bottom template"
      />
      <Grid item xs={12}>
        <TemplateButtons
          id="elicitatation-question-template-edit-button"
          templateType={elicitationMethod as TemplateType}
          saveCallback={saveTemplate}
        />
      </Grid>
    </Grid>
  );
}
