export function determineStepSizes([lowerScalesValue, upperScalesValue]: [
  number,
  number
]) {
  const interval = upperScalesValue - lowerScalesValue;
  const magnitude = Math.floor(Math.log10(interval));
  return [
    Math.pow(10, magnitude),
    Math.pow(10, magnitude - 1),
    Math.pow(10, magnitude - 2)
  ];
}
