import _ from 'lodash';
import {ElicitationMethod} from '../../../Type/ElicitationMethod';

export function calculateImportance(
  criterionId: string,
  weights: Record<string, number>,
  elicitationMethod: ElicitationMethod
): string {
  if (weights[criterionId] !== undefined) {
    switch (elicitationMethod) {
      case 'ranking':
        return calculateRank(weights, criterionId);
      default:
        return calculateImportanceFromWeights(weights, criterionId);
    }
  } else {
    return '?';
  }
}

function calculateRank(
  weights: Record<string, number>,
  criterionId: string
): string {
  const rankByCriterionId: Record<string, number> = _(weights)
    .toPairs()
    .sortBy('1')
    .reverse()
    .map(([critId], idx) => {
      return [critId, idx + 1];
    })
    .fromPairs()
    .value();
  return `${rankByCriterionId[criterionId]}`;
}

function calculateImportanceFromWeights(
  weights: Record<string, number>,
  criterionId: string
): string {
  const maxWeight = _(weights).values().max();
  return Math.round((weights[criterionId] / maxWeight!) * 100) + '%';
}
