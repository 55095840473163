import {Grid, Typography} from '@material-ui/core';
import _ from 'lodash';
import {useContext} from 'react';
import {AppContext} from '../../../../AppContext/AppContext';
import ExtraQuestionsTable from './ExtraQuestionsTable/ExtraQuestionsTable';

export default function ExtraQuestions(): JSX.Element {
  const {extraQuestions} = useContext(AppContext);

  return (
    <Grid container>
      {!_.isEmpty(extraQuestions) ? (
        <ExtraQuestionsTable />
      ) : (
        <Grid item xs={12}>
          <Typography id="no-questions-message">
            No questions have been added.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
