import axios, {AxiosError} from 'axios';
import {useCallback, useContext, useEffect, useState} from 'react';
import {AppContext} from './AppContext/AppContext';
import AppRouter from './AppRouter/AppRouter';
import {ErrorContext} from './Error/ErrorContext';
import ISurvey from './Interface/ISurvey';

export default function ContextSetter({
  match
}: {
  match: {path: string; params: {surveyId: string}};
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [ownerChecked, setOwnerChecked] = useState(false);
  const {setError} = useContext(ErrorContext);
  const {
    setIsUserOwner,
    setId,
    setHostUrl,
    setStatus,
    setTitle,
    setTherapeuticContext,
    setAlternatives,
    setRows,
    setIntroduction,
    setElicitationMethod,
    setElicitationTemplate,
    setIdentificationQuestion,
    setFinishSurveyMessage,
    setShowCbmPieChart,
    setAreExtraQuestionsOn,
    setExtraQuestions
  } = useContext(AppContext);

  const errorCallback = useCallback(
    (error: AxiosError) => {
      setError(error.message + ', ' + error.response!.data);
    },
    [setError]
  );

  const getSurvey = useCallback(() => {
    axios
      .get(`/api/v1/survey/${match.params.surveyId}`)
      .then((response) => {
        const survey: ISurvey = response.data;
        setTitle(survey.title);
        setAlternatives(survey.alternatives);
        setAreExtraQuestionsOn(survey.areExtraQuestionsOn);
        setElicitationMethod(survey.elicitationMethod);
        setElicitationTemplate(survey.elicitationTemplate);
        setExtraQuestions(survey.extraQuestions);
        setFinishSurveyMessage(survey.finishSurveyMessage);
        setId(survey.id);
        setIdentificationQuestion(survey.identificationQuestion);
        setIntroduction(survey.introduction);
        setRows(survey.effectsTableRows);
        setShowCbmPieChart(survey.showCbmPieChart);
        setStatus(survey.status);
        setTherapeuticContext(survey.therapeuticContext);
        document.title = `Survey: ${survey.title}`;
        setIsLoaded(true);
      })
      .catch(errorCallback);
  }, [
    errorCallback,
    match.params.surveyId,
    setAlternatives,
    setAreExtraQuestionsOn,
    setElicitationMethod,
    setElicitationTemplate,
    setExtraQuestions,
    setFinishSurveyMessage,
    setId,
    setIdentificationQuestion,
    setIntroduction,
    setRows,
    setShowCbmPieChart,
    setStatus,
    setTherapeuticContext,
    setTitle
  ]);

  const getHost = useCallback(() => {
    axios
      .get('/')
      .then((response) => {
        setHostUrl(response.request.responseURL.slice(0, -1));
      })
      .catch(errorCallback);
  }, [errorCallback, setHostUrl]);

  const checkIsUserOwner = useCallback(() => {
    axios
      .get(`/api/v1/survey/${match.params.surveyId}/isUserOwner`)
      .then((response) => {
        setIsUserOwner(response.data);
        setOwnerChecked(true);
      })
      .catch(errorCallback);
  }, [errorCallback, match.params.surveyId, setIsUserOwner]);

  useEffect(() => {
    checkIsUserOwner();
    getSurvey();
    getHost();
  }, [checkIsUserOwner, getHost, getSurvey]);

  return isLoaded && ownerChecked ? (
    <AppRouter path={match.path} />
  ) : (
    <span>Loading..</span>
  );
}
