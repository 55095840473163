import Typography from '@material-ui/core/Typography';
import {useContext} from 'react';
import {AppContext} from '../../AppContext/AppContext';

export default function SurveyStatus() {
  const {status} = useContext(AppContext);
  function getText(): string {
    if (status === 'published') {
      return 'The survey is published and cannot be edited';
    } else if (status === 'closed') {
      return 'The survey is closed and does not accept new responses';
    } else {
      return 'The survey is not published and can be edited';
    }
  }

  return (
    <Typography id="survey-status" variant="h6" align="center">
      {getText()}
    </Typography>
  );
}
