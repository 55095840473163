import Grid from '@material-ui/core/Grid';
import {useContext} from 'react';
import {AppContext} from '../../AppContext/AppContext';
import {ErrorContext} from '../../Error/ErrorContext';
import EffectsTable from '../EffectsTable/EffectsTable';
import ScaleRanges from '../ScaleRanges/ScaleRanges';

export default function ProblemDefition() {
  const {isUserOwner} = useContext(AppContext);
  const {setError} = useContext(ErrorContext);

  if (!isUserOwner) {
    setError('Insufficient rights');
  }

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12}>
        <EffectsTable />
      </Grid>
      <Grid item xs={12}>
        <ScaleRanges />
      </Grid>
    </Grid>
  );
}
