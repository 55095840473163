import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import Axios, {AxiosError} from 'axios';
import {ChangeEvent, useContext, useState} from 'react';
import {AppContext} from '../../../../../../AppContext/AppContext';
import DialogTitleWithCross from '../../../../../../DialogTitleWithCross/DialogTitleWithCross';
import {ErrorContext} from '../../../../../../Error/ErrorContext';
import IExtraQuestion from '../../../../../../Interface/IExtraQuestion';
import AsyncSingleClickButton from '../../../../../../util/AsyncSingleClickButton/AsyncSingleClickButton';
export default function EditQuestionButton({
  extraQuestion,
  index
}: {
  extraQuestion: IExtraQuestion;
  index: number;
}): JSX.Element {
  const {setError} = useContext(ErrorContext);
  const {id, editQuestion} = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const [question, setQuestion] = useState<string>('');
  const [columnHeader, setColumnHeader] = useState<string>('');

  function closeDialog() {
    setIsOpen(false);
  }

  function openDialog() {
    setQuestion(extraQuestion.question);
    setColumnHeader(extraQuestion.columnHeader);
    setIsOpen(true);
  }

  const handleEditButtonClick = openDialog;

  function handleQuestionChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void {
    setQuestion(event.target.value);
  }

  function handleColumnHeaderChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void {
    setColumnHeader(event.target.value);
  }

  function handleEditClick(): Promise<void> {
    const updatedQuestion = {
      id: extraQuestion.id,
      question: question,
      columnHeader: columnHeader
    };
    return Axios.put(
      `/api/v1/survey/${id}/extraQuestion/${extraQuestion.id}`,
      updatedQuestion
    )
      .then(() => {
        editQuestion(updatedQuestion);
        closeDialog();
      })
      .catch((error: AxiosError) => {
        setError(error.message + ', ' + error.response!.data);
      });
  }

  return (
    <>
      <Tooltip title="edit question">
        <IconButton
          id={`edit-question-${index}`}
          size="small"
          color="primary"
          onClick={handleEditButtonClick}
        >
          <Edit />
        </IconButton>
      </Tooltip>
      <Dialog open={isOpen} onClose={closeDialog} fullWidth maxWidth={'md'}>
        <DialogTitleWithCross
          id="add-extra-question-dialog"
          onClose={closeDialog}
        >
          Edit survey question
        </DialogTitleWithCross>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography>Column header</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="header-input"
                variant="outlined"
                value={columnHeader}
                onChange={handleColumnHeaderChange}
                fullWidth
                helperText={!columnHeader ? 'No value entered' : ''}
                error={!columnHeader}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Question</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="question-input"
                variant="outlined"
                value={question}
                onChange={handleQuestionChange}
                rows="3"
                multiline
                fullWidth
                helperText={!question ? 'No value entered' : ''}
                error={!question}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container xs={12} justify="flex-start" spacing={2}>
            <Grid item>
              <AsyncSingleClickButton
                id="confirm-edit-question-button"
                asyncFunction={handleEditClick}
                disabled={!question || !columnHeader}
                variant="contained"
                color="primary"
              >
                Edit
              </AsyncSingleClickButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}
