import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import {useContext} from 'react';
import {AppContext} from '../AppContext/AppContext';
import IEvent from '../Interface/IEvent';
import {SurveyContext} from '../SurveyContext/SurveyContext';

export default function IntroductionInformation() {
  const {currentStep, setCurrentStep, setName, name} =
    useContext(SurveyContext);
  const {introduction, identificationQuestion} = useContext(AppContext);

  function handleNameChange(event: IEvent): void {
    setName(event.target.value);
  }

  function handleNextClick() {
    setCurrentStep(currentStep + 1);
  }

  function handleKey(event: {keyCode: number}) {
    if (event.keyCode === 13 && name) {
      handleNextClick();
    }
  }

  return (
    <Grid container item spacing={2} sm={12} md={6} component={Paper}>
      <Grid
        item
        xs={12}
        id="survey-introduction"
        dangerouslySetInnerHTML={{__html: introduction}}
      />
      <Grid
        item
        xs={12}
        id="identification-question"
        dangerouslySetInnerHTML={{__html: identificationQuestion}}
      />
      <Grid container item xs={12} justify="center">
        <Grid item>
          <TextField
            id="name-input"
            placeholder="Enter name here..."
            variant="outlined"
            value={name}
            onChange={handleNameChange}
            autoFocus
            helperText={!name ? 'No name entered' : ''}
            onKeyDown={handleKey}
          />
        </Grid>
      </Grid>
      <Grid item xs={9}>
        <ButtonGroup variant="contained" size="medium" color="primary">
          <Button id="previous-button" disabled>
            Previous
          </Button>
          <Button id="next-button" disabled={!name} onClick={handleNextClick}>
            Next
          </Button>
        </ButtonGroup>
      </Grid>
    </Grid>
  );
}
