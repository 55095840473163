import {TableCell, TableRow} from '@material-ui/core';
import {useContext} from 'react';
import {AppContext} from '../../../../../AppContext/AppContext';
import IExtraQuestion from '../../../../../Interface/IExtraQuestion';
import ShowIf from '../../../../../util/ShowIf/ShowIf';
import DeleteQuestionButton from './DeleteQuestionButton/DeleteQuestionButton';
import EditQuestionButton from './EditQuestionButton/EditQuestionButton';

export default function ExtraQuestionsTableRow({
  extraQuestion,
  index
}: {
  extraQuestion: IExtraQuestion;
  index: number;
}): JSX.Element {
  const {status} = useContext(AppContext);
  return (
    <TableRow key={`extra-question-row-${index}`}>
      <TableCell id={`extra-question-header-${index}`}>
        {extraQuestion.columnHeader}{' '}
      </TableCell>
      <TableCell style={{width: '99%'}} id={`extra-question-${index}`}>
        {extraQuestion.question}{' '}
      </TableCell>
      <ShowIf condition={status === 'created'}>
        <TableCell id={`edit-extra-question-${index}`}>
          <EditQuestionButton extraQuestion={extraQuestion} index={index} />
        </TableCell>
        <TableCell id={`delete-extra-question-${index}`}>
          <DeleteQuestionButton extraQuestion={extraQuestion} index={index} />
        </TableCell>
      </ShowIf>
    </TableRow>
  );
}
